import Axios from 'axios'
import { TokenService } from '~/services/token.service'

export const axios = Axios.create({
  baseURL: process.env.API_HOST,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Ensures that the Authorization header is set once the token is available
axios.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken()

    if (token) {
      config.headers!.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => Promise.reject(error)
)

// Interceptor to handle 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    if ((error.response.status === 401 || error.response.status === 403) && !window.location.pathname.includes('/login')) {
      TokenService.removeToken()
      window.location.href = '/login?sessionExpired=true'
    }
    return Promise.reject(error)
  }
)
