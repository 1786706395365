import { Store } from './stores'
import { Reward, Values, PartnerId, VoucherAccount } from './index'
import { PageState, SortState } from '~/types/state'

export interface VoucherChanges {
  date:number;
  account: VoucherAccount;
  changes: Array<string>;
}

export interface Voucher {
  id:number;
  reward:Reward;
  rewardCategory:string;
  partner:PartnerId;
  account:VoucherAccount;
  certificateId: string;
  giftMeLink: string;
  voucherNumber: number;
  tag: string;
  status: string;
  fraudSuspected: boolean;
  transactionId: string;
  details: string;
  cancelReason: string | null;
  value:Values;
  changes: Array<VoucherChanges>;
  promoCode?: string;
  promoExpiryDate: number;
  redeemedBy: string;
  redemptionDate: number;
  dateExpired: number;
  expiryDate: number;
  distributedDate: number;
  issuedDate: number;
  modifiedDate: number;
  mobileNumber: string;
  quantity: number;
  emailAddress: string;
  store: null | Store;
}

export interface VoucherHistory {
  certificateId:string;
  firstName: string;
  lastName: string;
  status: string;
  modifiedDate: string | null;
}

export interface VoucherDetails {
  id:number;
  user: string;
  status: string;
  modifiedDate: string | null;
}

export interface VoucherDetailsChangesAccount {
  accountNumber:string;
  firstName: string;
  lastName: string;
  id: string;
}
export interface VoucherDetailsChanges {
  account: Array<VoucherDetailsChangesAccount>;
  changes: Array<string>;
  date: number;
}

export interface VoucherDetailsData {
  certificateId: string
  changes: Array<VoucherDetailsChanges>;
  firstName: string;
  id: number;
  lastName: string;
  reward: string;
  status: string;
  updatedOn: string | null;
  store?: string;
  partner?: string;
}

export interface VoucherSummary{
  status:string;
  partnerName:string;
  rewardName: string;
  count: number;
  sum: number;
}

export interface VoucherUpdate {
  status?: string;
  fraudSuspected?: boolean;
  cancelReason?: string;
  expiryDate?: string;
  distributedDate?: string;
  store?: number;
}

export interface UpdateVoucherState {
  id: number;
  voucher: VoucherUpdate;
  resultsAction: boolean;
}

export interface VoucherSummaryFilterState {
  filter: {
      partnerId: number;
      reward: number;
      status: string;
      dateBy:string;
      startDate: string;
      endDate: string;
  };
}

export interface VoucherFilterState {
  gkvr_no: number,
  voucher_no: string,
  tag: string,
  filter: {
      partnerId: number;
      reward: number;
      status: string;
      fraudSuspected: boolean | '';
      dateBy:string;
      startDate: string;
      endDate: string;
      store: number;
      promoCode: string;
  };
}

export interface VoucherDetailsFilterState {
  voucher_no: string,
  filter: {
      partnerId: number;
      reward: number;
      status: string;
      dateBy:string;
      startDate: string;
      endDate: string;
      store: number;
  };
}

export interface InitVoucherSummaryState {
  voucherSummary: Array < VoucherSummary > ;
  currentVoucherSummary: Array < VoucherSummary > ;
  filter: VoucherSummaryFilterState;
  sort: SortState;
  pageStatus: PageState;
}

export interface InitVoucherDetailsState {
  voucherDetails: Array < Voucher > ;
  currentVoucherDetails: Array < Voucher > ;
  filter: VoucherDetailsFilterState;
  sort: SortState;
  pageStatus: PageState;
}

export interface InitVoucherState {
  vouchers: Array < Voucher > ;
  currentVouchers: Array < Voucher > ;
  filter: VoucherFilterState;
  sort: SortState;
  pageStatus: PageState;
  previouslySentVoucher?: number;
}

export interface VoucherStatusMessageStore {
  [id: string]: {
    message:string
  };
}

export enum VoucherStatus {
  ISSUED = 'issued',
  APPROVED = 'approved',
  DISTRIBUTED = 'distributed',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  FAILED = 'failed',
}

export enum VoucherHistoryPage {
  SYSTEM = 'System',
  DISTRIBUTED_DATE = 'distributedDate',
  DATE_HR_MM_SS_A_FORMAT = 'MMM-DD-YY, h:mm:ss a',
  PAGE_TITLE = 'Voucher modified by System',
  STATUS = 'status',
  STORE = 'storeId'
}

export enum VoucherActionKeys {
  DISTRIBUTE = 'distributeVouchers'
}

export enum VoucherRewardCategories {
  PHONE_CREDIT = 'Phone Credit'
}

export enum VoucherDateBy {
  REDEEMED = 'redeemed',
  ISSUED = 'issued',
  DISTRIBUTED = 'distributed',
  UPDATED = 'updated',
  EXPIRED = 'expired'
}

export enum ErrorMessage {
  NOT_FOUND = 'Voucher not found. Please check to ensure that the keyed GKVR # & Voucher # is correct',
  VOUCHER_APPROVED_ERROR = 'An error occured, voucher was not approved. Voucher type and store does not match.',
  VOUCHER_CANCEL_ERROR = 'An error occured voucher was not cancelled',
}

export enum APIReponseMessages {
  VOUCHER_APPROVED = 'Voucher was successfully approved',
  VOUCHER_CANCEL = 'Voucher was successfully cancelled'
}

export enum VoucherInitSortState {
  SORT_FIELD = 'createdOn',
  SORT_ORDER_DESC = 'desc',
  SORT_ORDER_ASC = 'asc',
}
