import { AxiosResponse } from 'axios'
import { axios } from '~/lib/axios'
import { TokenService } from './token.service'
import { BaseService } from './base.service'
import { EmailTrigger } from '~/types/emailTriggers'

class EmailTriggerService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  public async getEmailTriggers (): Promise <any> {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get(`${apiHost}/api/v1/triggers/email`, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async toggleEmailTriggers (editRequest: Array <EmailTrigger>): Promise <any> {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }
    try {
      result = await axios.put(`${apiHost}/api/v1/triggers/email`, editRequest, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }
};

export default EmailTriggerService
