import CookieManager from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { Credentials } from '~/types'
const CookieManagerServer = require('cookieparser')

const ACCESS_TOKEN_KEY = 'access_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/

class TokenService {
  public getToken (cookie?: any): string {
    return this.getCredentials(cookie).accessToken
  }

  public saveToken (accessToken: string) {
    CookieManager.set(ACCESS_TOKEN_KEY, accessToken)
  }

  public removeToken () {
    CookieManager.remove(ACCESS_TOKEN_KEY)
  }

  public getCredentials (cookie?: any): Credentials {
    if (process.server && cookie) {
      const parsed = CookieManagerServer.parse(cookie)
      return {
        accessToken: parsed.access_token
      }
    }

    return {
      accessToken: CookieManager.get(ACCESS_TOKEN_KEY) || ''
    }
  }

  public saveCredentials (accessToken: string) {
    CookieManager.set(ACCESS_TOKEN_KEY, accessToken)
  }

  public removeCookies () {
    CookieManager.remove(ACCESS_TOKEN_KEY)
  }

  public decodeJwt (accessToken: string) {
    const decoded = jwtDecode(accessToken)
    return decoded
  }
};

const tokenService = new TokenService()

export {
  tokenService as TokenService
}
