import { AxiosResponse, AxiosError } from 'axios'
import { axios } from '~/lib/axios'
import { BaseService } from './base.service'
import { StoreShort } from '~/types/stores'

class StoreService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  formDataToObject = (formData: FormData) => {
    const data: { [key: string]: string } = {}
    if (formData) {
      formData.forEach((v, k) => (data[k] = v as string))
    }

    return data
  }

  public async createStore (createRequest: StoreShort): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.post(`${apiHost}/api/v1/stores`, this.formDataToObject(createRequest as unknown as FormData), { headers: this.headers })

      return Promise.resolve(result)
    } catch (error: any) {
      console.log(error)
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async editStore (id: number, editRequest: StoreShort): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.put(`${apiHost}/api/v1/stores/${id}`, this.formDataToObject(editRequest as unknown as FormData), { headers: this.headers })

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async deleteStore (id: number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.delete(`${apiHost}/api/v1/stores/${id}`, { headers: this.headers })
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async getAllStores (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.get(`${apiHost}/api/v1/stores`, { headers: this.headers })
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async getAllStoresBasedOnPartnerId (partnerId?: number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.get(`${apiHost}/api/v1/stores/search${partnerId ? `?partnerId=${partnerId}` : ''}`, { headers: this.headers })
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async searchStores (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.get(`${apiHost}/api/v1/stores/search?shouldPage=true&size=20&${search}`, { headers: this.headers })

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  public async loadCsv (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    try {
      result = await axios.get(`${apiHost}/api/v1/stores/search?${search}`, { headers: this.headers })

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve((error as AxiosError).response?.status)
    }
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
};

export default StoreService
