import Axios, { AxiosResponse } from 'axios'
import { TokenService } from './token.service'
import { BaseService } from './base.service'
import { Credentials, ResetPassword, UpdatePassword } from '~/types'

class AccountService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  public async authenticateUser (email: string, password: string): Promise<Credentials> {
    let request: object
    let result: any = null
    let credentials: Credentials | null = null
    const nuxtApiPath = this.configs.NUXT_API_PATH
    const nuxtServerHost = this.configs.NUXT_SERVER_HOST
    const grant_type = 'password'

    request = {
      username: email,
      password,
      grant_type
    }

    try {
      result = await Axios.post(`${nuxtServerHost}${nuxtApiPath}`, request)

      // save token
      credentials = {
        accessToken: result.data.data.access_token
      }

      return Promise.resolve(credentials)
    } catch (error: any) {
      const { response } = error
      const { config, headers, ...errorObject } = response
      return Promise.reject(errorObject.data.error.message)
    }
  }

  public async authenticateRequest (): Promise<Credentials> {
    let request: object
    let result: any = null
    let credentials: Credentials | null = null
    const nuxtApiPath = this.configs.NUXT_API_PATH
    const nuxtServerHost = this.configs.NUXT_SERVER_HOST
    const grant_type = 'client_credentials'

    request = {
      grant_type
    }

    try {
      result = await Axios.post(`${nuxtServerHost}${nuxtApiPath}`, request)

      if (result.data.passed) {
        // save token
        credentials = {
          accessToken: result.data.data.access_token
        }

        return Promise.resolve(credentials)
      } else {
        throw result.data.error
      }
    } catch (error: any) {
      return Promise.reject(false)
    }
  }

  public async requestPasswordReset (email: string): Promise<boolean> {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    const body = {
      emailAddress: email
    }

    try {
      result = await Axios.post(`${apiHost}/api/v1/account/password/reset`, body, config)

      return Promise.resolve(result.data)
    } catch (error: any) {
      return Promise.reject(false)
    }
  }

  public async resetPasswordRequest (resetRequest: ResetPassword): Promise<boolean> {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.post(`${apiHost}/api/v1/account/password/apply`, resetRequest, config)

      return Promise.resolve(result.data)
    } catch (error: any) {
      const { response } = error
      const { config, headers, ...errorObject } = response

      return Promise.reject(errorObject.data.error.errors[0].message)
    }
  }

  public async loadAccountInfo (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.get(`${apiHost}/api/v1/account`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async updateAccountpassword (updateRequest: UpdatePassword): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await Axios.put(`${apiHost}/api/v1/account`, updateRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
};

export default AccountService
