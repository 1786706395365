import { AxiosResponse } from 'axios'
import { axios } from '~/lib/axios'
import { TokenService } from './token.service'
import { BaseService } from './base.service'
import { GiftMeCatalogResponse, RewardUpdate } from '~/types/rewards'
import { Values, BlinkSkyRewardResponse } from '~/types'

class RewardService extends BaseService {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  toUrlEncoded = (obj: any) => {
    return Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
  }

  public async createReward (createRequest: RewardUpdate): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.post(`${apiHost}/api/v1/rewards`, createRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async createRewardValue (id:number, createRequest: Values): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.post(`${apiHost}/api/v1/rewards/${id}/value`, createRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async editReward (id: number, editRequest: RewardUpdate): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.put(`${apiHost}/api/v1/rewards/${id}`, editRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async editRewardValue (rewardId: number, valueId: number, editRequest: Values): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.put(`${apiHost}/api/v1/rewards/${rewardId}/value/${valueId}`, editRequest, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async deleteReward (id: number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.delete(`${apiHost}/api/v1/rewards/${id}`, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async getAllRewards (cookie?: any): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken(cookie)}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get(`${apiHost}/api/v1/rewards`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async getRewardsByPartner (id:number): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      if (id === 0) {
        result = await axios.get(`${apiHost}/api/v1/rewards`, config)
      } else {
        result = await axios.get(`${apiHost}/api/v1/rewards/search?partner=${id}&status=all`, config)
      }

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async getBlinkSkyCatalog (): Promise < AxiosResponse<BlinkSkyRewardResponse> > {
    let result: AxiosResponse<BlinkSkyRewardResponse>
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get<BlinkSkyRewardResponse>(`${apiHost}/api/v1/rewards/blinkSky`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async getGiftMeCatalog (): Promise<AxiosResponse<Array<GiftMeCatalogResponse>>> {
    let result: AxiosResponse<Array<GiftMeCatalogResponse>>
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get(`${apiHost}/api/v1/rewards/giftMe`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async searchRewards (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get(`${apiHost}/api/v1/rewards/search?shouldPage=true&size=100&${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  public async loadCsv (search:string): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }

    try {
      result = await axios.get(`${apiHost}/api/v1/rewards/search?${search}`, config)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.resolve(error.response.status)
    }
  }

  async adminRedeemRewards (requestBody: { valueId: number; quantity: number; details: string; customerId: number; }, rewardId: number) {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'application/json'
      }
    }
    try {
      result = await axios.post(`${apiHost}/api/v1/rewards/${rewardId}/adminRedeem`, requestBody, config)
      return Promise.resolve(result)
    } catch (error: any) {
      console.log(error)
      return Promise.resolve(error.response.status)
    }
  }

  async adminBulkRedeemRewards (rewardId: number, formData: FormData): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      result = await axios.post(`${apiHost}/api/v1/rewards/${rewardId}/bulkRedeem`, formData, config)
      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

  async rewardStatuses (): Promise < any > {
    let result: AxiosResponse
    const apiHost = this.configs.API_HOST

    const config = {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      result = await axios.get(`${apiHost}/api/v1/rewards/statuses`, config)
      return Promise.resolve(result)
    } catch (error: any) {
      console.log(error)

      return Promise.resolve(error.response.status)
    }
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
};

export default RewardService
